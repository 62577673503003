import React, { useReducer, useContext } from 'react';
import reducer from './reducer';
import {
  SET_TOKEN,
} from './actions';

const initialState = {
  token: null,
};

const PublicContext = React.createContext();

const PublicProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setToken = (token) => {
    dispatch({
      type: SET_TOKEN,
      payload: token,
    });
  };

  return (
    <PublicContext.Provider
      value={{
        ...state,
        setToken,
      }}
    >
      {children}
    </PublicContext.Provider>
  );
};

const usePublicContext = () => {
  return useContext(PublicContext);
};

export { PublicProvider, initialState, usePublicContext };
