import {
  SET_APP_PAGE,
  SET_TICKER,
  SET_AVAILABLE_TICKERS,
  SET_CONFIG,
  SET_CONFIGS,
  SET_ACTIVE_DISPLAY,
} from './actions';

const reducer = (state, action) => {
  if (action.type === SET_APP_PAGE) {
    return {
      ...state,
      appPage: action.payload,
    };
  }
  if (action.type === SET_TICKER) {
    return {
      ...state,
      ticker: action.payload,
    };
  }
  if (action.type === SET_AVAILABLE_TICKERS) {
    return {
      ...state,
      availableTickers: action.payload,
    };
  }
  if (action.type === SET_CONFIG) {
    return {
      ...state,
      [action.payload.type+'s']: {
        ...state[action.payload.type+'s'],
        [action.payload.configId]: action.payload.config,
      }
    };
  }
  if (action.type === SET_CONFIGS) {
    return {
      ...state,
      [action.payload.type + 's']: action.payload.configs,
    };
  }
  if (action.type === SET_ACTIVE_DISPLAY) {
    return {
      ...state,
      activeDisplay: action.payload,
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
