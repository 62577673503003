const tickerChartConfig = {
  name: 'Basic Chart',
  id: 'C000001',
  extendedSession: !false,
  showVolume: true,
  timeScroll: false,
  priceScroll: false,
  defaultTimeInterval: 'D',
  defaultCalendarTimeframe: 'D',
  //in addition to visibleRangeReg, need the ability to
  //set startDate anchors and either auto-set candle size
  //or give relevant manual options for that given anchor
  timeIntervals: [
    {
      name: '1m',
      visibleRangeReg: (6.5 * 60) / 1, //24hrs
      // visibleRangeExt: 6.5 * 60, //24hrs
    },
    {
      name: '5m',
      visibleRangeReg: (6.5 * 60 * 2) / 5, //48hrs
    },
    {
      name: '10m',
      visibleRangeReg: (6.5 * 60 * 3) / 10, //72hrs
    },
    {
      name: '15m',
      visibleRangeReg: (6.5 * 60 * 5) / 15, //5 days
    },
    {
      name: '30m',
      visibleRangeReg: (6.5 * 60 * 10) / 30, //10 days
    },
    {
      name: '65m',
      visibleRangeReg: (6.5 * 60 * 21) / 65, //21 days
    },
    {
      name: '195m',
    },
    {
      name: 'D',
    },
    {
      name: 'W',
    },
    {
      name: 'M',
    },
  ],
  calendarTimeframes: ['D', 'W', 'M', 'Q', 'Y'],
  indicators: {
    ma: [50, 200],
    avwap: ['ath'],
  },
  priceLines: ['ath'],
  verticalLines: ['last earnings'], //labels also available, see examples
  renderData: [
    {
      name: 'move',
      displayName: 'Move',
      obj: 'eodCalcs',
      key: 'move',
    },
    {
      name: 'marketCap',
      displayName: 'MarketCap',
      obj: 'realTimeCalcs',
      key: 'marketCap',
    },
    {
      name: 'name',
      displayName: 'Name',
      obj: 'tickerDetails',
      key: 'name',
    },
    {
      name: 'sector',
      displayName: 'Sector',
      obj: 'tickerDetails',
      key: 'sector',
    },
    {
      name: 'industry',
      displayName: 'Industry',
      obj: 'tickerDetails',
      key: 'industry',
    },
    {
      name: 'sic',
      displayName: 'SIC',
      obj: 'tickerDetails',
      key: 'sic',
    },
    {
      name: 'description',
      displayName: 'Description',
      obj: 'tickerDetails',
      key: 'description',
    },
    {
      name: 'hexColor',
      displayName: 'Hex Color',
      obj: 'tickerDetails',
      key: 'hexColor',
    },
  ],
};

export default tickerChartConfig;
