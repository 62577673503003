import React, { useReducer, useContext } from 'react';
import reducer from './reducer';
import {
  SET_APP_PAGE,
  SET_TICKER,
  SET_AVAILABLE_TICKERS,
  SET_ACTIVE_DISPLAY,
  SET_CONFIG,
  SET_CONFIGS,
} from './actions';
import tickerChartConfig from './tickerChartConfig';
import { useAuth0 } from '@auth0/auth0-react';
import { usePublicContext } from '../../../context/publicContext';
import createApi from '../../../utils/createApi';
import { useUserContext } from '../user/userContext';

const defaultTicker = '';

const initialState = {
  appPage: 'home',
  ticker: defaultTicker,
  availableTickers: [defaultTicker],
  tickerChartConfig: tickerChartConfig,
  activeDisplay: '',
  displays: {},
  layouts: {},
  charts: {},
  lists: {},
  widgets: {},
};

const ConfigContext = React.createContext();

const ConfigProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { logout } = useAuth0();
  const { token } = usePublicContext();
  const api = createApi(token, logout);
  // const api = createApi(token, logout, true);

  const { id, firstName, lastName, setIsLoading } = useUserContext();

  const setAvailableTickers = (availableTickers) => {
    dispatch({
      type: SET_AVAILABLE_TICKERS,
      payload: availableTickers,
    });
  };

  const setTicker = (ticker) => {
    dispatch({
      type: SET_TICKER,
      payload: ticker,
    });
  };

  const setAppPage = (appPage) => {
    dispatch({
      type: SET_APP_PAGE,
      payload: appPage,
    });
  };

  const setConfig = (type, config, configId) => {
    dispatch({
      type: SET_CONFIG,
      payload: { type, config, configId },
    });
  };

  const setConfigs = (type, configs) => {
    const configsObj = {};
    configs.forEach((config) => {
      configsObj[config._id] = config;
    });
    dispatch({
      type: SET_CONFIGS,
      payload: { type, configs: configsObj },
    });
  };

  const setActiveDisplay = (activeDisplayId) => {
    console.log('setActiveDisplay', activeDisplayId);
    dispatch({
      type: SET_ACTIVE_DISPLAY,
      payload: activeDisplayId,
    });
  };

  //fix api to not return error if no configs for given type
  //eventually consolidate into one api call
  const loadConfigs = async (typeOfConfig) => {
    const types =
      typeOfConfig === 'all'
        ? ['display', 'layout', 'chart', 'list', 'widget']
        : [typeOfConfig];
    setIsLoading(true);
    console.log('load configs', types);
    for (let i = 0; i < types.length; i++) {
      const type = types[i];
      const params = {
        type,
        id,
        firstName,
        lastName,
      };
      try {
        const { data } = await api.get('/configs', { params });
        if (data.msg === 'success' && data.configs) {
          console.log('Loaded', data);
          setConfigs(type, data.configs);
          setIsLoading(false);
        } else {
          console.log('Error', data);
          setIsLoading(false);
        }
        //default to first display
        if (type === 'display') {
          setActiveDisplay(data.configs[0]._id);
        }
      } catch (err) {
        console.log('Error', err);
        setIsLoading(false);
      }
    }
  };

  const addConfig = async (type, settings, copy = false) => {
    console.log('add config');
    let name;
    let count = 1;
    while (!name) {
      if (!copy) {
        name = settings.name;
        break;
      } else {
        console.log('copy name');
        const tempName = `${settings.name} - copy${count}`;
        count++;
        let duplicate = false;
        for (const key in state[`${type}s`]) {
          if (state[`${type}s`][key].name === tempName) {
            duplicate = true;
            break;
          }
        }
        if (!duplicate) {
          name = tempName;
          break;
        }
      }
    }
    if (!settings.name || settings.name === '') {
      return { status: 0, msg: 'Please enter a name' };
    }
    //check for duplicate name
    const configs = state[`${type}s`];
    for (let configId in configs) {
      if (configs[configId].name === name) {
        return {
          status: 0,
          msg: 'Name already exists. Choose a different name.',
        };
      }
    }
    console.log('add config', type, settings);
    setIsLoading(true);
    const body = {
      type,
      settings: {
        ...settings,
        name: name,
      },
      user: {
        id,
        firstName,
        lastName,
      },
    };
    if (body.settings._id) delete body.settings._id; //for copy
    if (body.settings.owner) delete body.settings.owner; //for copy
    if (body.settings.users) delete body.settings.users; //for copy
    try {
      const { data } = await api.post('/configs', body);
      if (data.msg === 'success' && data.configId) {
        console.log('Saved', data);
        const newConfig = {
          ...settings,
          _id: data.configId,
          name: name,
          owner: body.user,
          users: [body.user.id],
        };
        setConfig(type, newConfig, data.configId);
        setIsLoading(false);
        return { status: 1, msg: 'Saved successfully' };
      } else {
        console.log('Error', data);
        setIsLoading(false);
        return { status: 0, msg: 'Error, did not save' };
      }
    } catch (err) {
      console.log('Error', err);
      setIsLoading(false);
      return { status: 0, msg: 'Error, did not save' };
    }
  };

  const editConfig = async (type, settings) => {
    console.log('edit config');
    if (!settings._id) {
      return { status: 0, msg: 'Need widget id to edit' };
    }
    setIsLoading(true);
    const body = {
      type,
      settings,
    };
    console.log('edit config', type, settings);
    try {
      const { data } = await api.put('/configs', body);
      if (data.msg === 'success') {
        console.log('Saved', data);
        setConfig(type, settings, settings._id);
        setIsLoading(false);
        return { status: 1, msg: 'Saved successfully' };
      } else {
        console.log('Error', data);
        setIsLoading(false);
        return { status: 0, msg: 'Error, did not save' };
      }
    } catch (err) {
      console.log('Error', err);
      setIsLoading(false);
      return { status: 0, msg: 'Error, did not save' };
    }
  };

  // if i am owner of deleted, delete from user but keep owner tag on config.
  // can later have option to be reassigned back to original owner
  const deleteConfig = async (type, settings) => {
    const configId = settings._id;
    const userId = id;
    try {
      const { data } = await api.delete('/configs', {
        params: { type, configId, userId },
      });
      if (data.msg === 'success') {
        const configs = state[`${type}s`];
        delete configs[configId];
        setConfigs(type, Object.values(configs));
        console.log('Deleted', data);
        return { status: 1, msg: 'Deleted successfully' };
      } else {
        console.log('Error', data);
        return { status: 0, msg: 'Error, did not delete' };
      }
    } catch (err) {
      console.log('Error', err);
      return { status: 0, msg: 'Error, did not delete' };
    }
  };

  return (
    <ConfigContext.Provider
      value={{
        ...state,
        setAppPage,
        setTicker,
        setAvailableTickers,
        setActiveDisplay,
        loadConfigs,
        addConfig,
        editConfig,
        deleteConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

const useConfigContext = () => {
  return useContext(ConfigContext);
};

export { ConfigProvider, initialState, useConfigContext };
