import {
  SET_TOKEN,
  SET_API,
} from './actions';

const reducer = (state, action) => {
  
  if (action.type === SET_TOKEN) {
    return {
      ...state,
      token: action.payload,
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
