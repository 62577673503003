import delayedData from './delayedData.js';

const getTimestamp = (dateObj) => {
  const timestamp = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
  return timestamp.getTime();
};

const getFormattedDateTodayUTC = (unixTimestamp) => {
  let timestamp;
  if (!unixTimestamp) {
    timestamp = new Date();
  } else {
    timestamp = new Date(unixTimestamp);
  }
  const year = timestamp.getUTCFullYear().toString();
  let month = timestamp.getUTCMonth() + 1;
  month = month.toString();
  let day = timestamp.getUTCDate().toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  const date = year + '-' + month + '-' + day;
  return date;
};

const getFormattedYesterdayDateUTC = (unixTimestamp) => {
  let timestamp;
  if (!unixTimestamp) {
    timestamp = new Date();
  } else {
    timestamp = new Date(unixTimestamp);
  }
  timestamp.setDate(timestamp.getDate() - 1);
  const year = timestamp.getUTCFullYear().toString();
  let month = timestamp.getUTCMonth() + 1;
  month = month.toString();
  let day = timestamp.getUTCDate().toString();
  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  const date = year + '-' + month + '-' + day;
  return date;
};

const timeToLocal = (originalTime) => {
  const oldTime = new Date(originalTime);
  const newTime = Date.UTC(
      oldTime.getFullYear(),
      oldTime.getMonth(),
      oldTime.getDate(),
      oldTime.getHours(),
      oldTime.getMinutes(),
      oldTime.getSeconds(),
      oldTime.getMilliseconds()
    ) / 1000
  return newTime;
}

const timeToSeconds = (time) => {
  const parts = time.split(':');
  const hours = parts[0];
  let minutes = parts[1];
  const seconds = parts[2];
  const totalSeconds =
    parseInt(hours, 10) * 3600 +
    parseInt(minutes, 10) * 60 +
    parseInt(seconds, 10);
  return totalSeconds;
};

const getLagTime = (timestamp) => {
  let date = new Date(timestamp);
  let localDate = new Date();
  if (delayedData) {
    localDate = new Date(localDate.getTime() - 15 * 60 * 1000);
  }
  let timeLag = localDate.getTime() - date.getTime();
  return timeLag;
};

const isDST = () => {
  var now = new Date();
  var jan = new Date(now.getFullYear(), 0, 1);
  var jul = new Date(now.getFullYear(), 6, 1);
  var dst = Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
  return now.getTimezoneOffset() < dst;
};

const getDstOffsetMins = () => {
  const offset = isDST() ? 0 : -60;
  return offset;
};

const getDstOffsetSecs = () => {
  const offset = isDST() ? 0 : -3600;
  return offset;
};

export {
  getTimestamp,
  timeToLocal,
  getFormattedDateTodayUTC,
  getFormattedYesterdayDateUTC,
  timeToSeconds,
  getLagTime,
  getDstOffsetMins,
  getDstOffsetSecs,
};