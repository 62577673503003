import React, { useReducer, useContext } from 'react';
import reducer from './reducer';
import {
  SET_IS_LOADING,
  SETUP_USER_SUCCESS,
} from './actions';
import { usePublicContext } from '../../../context/publicContext';
import createApi from '../../../utils/createApi';
import { useAuth0 } from '@auth0/auth0-react';

const initialState = {
  isLoading: false,
  id: null,
  firstName: null,
  lastName: null,
  email: null,
};

//add behavior tracking and recommendations logic here

const UserContext = React.createContext();

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { logout } = useAuth0();
  const { token } = usePublicContext();
  const api = createApi(token, logout);
  // const api = createApi(token, logout, true);

  const setIsLoading = (isLoading) => {
    dispatch({
      type: SET_IS_LOADING,
      payload: isLoading,
    });
  };

  const setupUser = async (user) => {
    console.log('setup user attempt with', user);
    setIsLoading(true);
    const { data } = await api.post('/user/setup-user', user);
    console.log('setup user with db data', data);
    dispatch({
      type: SETUP_USER_SUCCESS,
      payload: data,
    });
    console.log('setup user success');
  };

  return (
    <UserContext.Provider
      value={{
        ...state,
        setIsLoading,
        setupUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useUserContext = () => {
  return useContext(UserContext);
};

export { UserProvider, initialState, useUserContext };
