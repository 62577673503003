import axios from 'axios';

const createApi = (token, logoutFunc, isTest = false) => {
  const baseUrl = isTest ? 'http://localhost:4242' : process.env.REACT_APP_API_URL;
  const api = axios.create({
    baseURL: baseUrl,
  });
  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log('API error: ', error);
      if (error.response.status === 401) {
        //auto-logout on failed axios requests
        logoutFunc({ returnTo: window.location.origin });
      }
      return Promise.reject(error);
    }
  );
  return api;
};

export default createApi;