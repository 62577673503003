import { getFormattedDateTodayUTC } from './dateTimeFunctions.js';
import delayedData from './delayedData.js';
import { holidays, earlyClose } from './marketDates.js';
import { getDstOffsetMins } from './dateTimeFunctions.js';

//**broken in the first 15 min of market close**
const marketStatus = (timestampParam) => {
  let status;
  let timestamp = new Date();
  //delayed 15 min
  if (delayedData) {
    console.log('marketStatus delayed data')
    timestamp = new Date(timestamp.getTime() - (15 * 60 * 1000));
  }
  if (timestampParam) {
    timestamp = new Date(timestampParam);
  }
  //market closed all day
  if (timestamp.getUTCDay() === 0 || timestamp.getUTCDay() === 6) {
    status = 'closed';
    return status;
  }
  const todaysDate = getFormattedDateTodayUTC(timestamp);
  let isHoliday = false;
  holidays.forEach((el) => {
    if (el === todaysDate) {
      isHoliday = true;
    }
  })
  if (isHoliday) {
    return 'closed';
  }

  //utc times
  let minutesIntoDayUTC =
    timestamp.getUTCHours() * 60 + timestamp.getUTCMinutes() + getDstOffsetMins();
  if (minutesIntoDayUTC < 0) minutesIntoDayUTC += 60 * 24; //if dst offset makes minutes negative, add 24 hours of minutes
  const preMarketOpen = 8 * 60;
  const marketOpen = 13 * 60 + 30;
  let marketClose = 20 * 60;
  let postMarketClose = 0;
  let earlyCloseToday = false;
  earlyClose.forEach((el) => {
    if (todaysDate === el) {
      earlyCloseToday = true;
      marketClose = 17 * 60;
      postMarketClose = 21 * 60;
    }
  })
  if (minutesIntoDayUTC >= preMarketOpen && minutesIntoDayUTC < marketOpen) {
    status = 'premarket';
    return status;
  } else if (minutesIntoDayUTC >= marketOpen && minutesIntoDayUTC < marketClose) {
    status = 'open';
    return status;
  } else if (minutesIntoDayUTC >= marketClose) {
    status = 'postmarket';
    if (earlyCloseToday && minutesIntoDayUTC >= postMarketClose) {
      status = 'closed';
    }
    return status;
  } else {
    status = 'closed';
    return status;
  }
}

export default marketStatus;