import {
  getDstOffsetMins,
  getFormattedDateTodayUTC,
  getFormattedYesterdayDateUTC,
} from './dateTimeFunctions.js';
import marketStatus from './marketStatus.js';

//need to automate
const holidays = [
  '2022-06-20',
  '2022-07-04',
  '2022-09-05',
  '2022-11-24',
  '2022-12-26',
  '2023-01-02',
  '2023-01-16',
  '2023-02-20',
  '2023-04-07',
  '2023-05-29',
  '2023-06-19',
  '2023-07-04',
  '2023-09-04',
  '2023-11-23',
  '2023-12-25',
];

const earlyClose = ['2022-11-25', '2023-11-24'];

const getMarketCloseHour = (timestamp) => {
  let marketCloseUTCHour = 20;
  earlyClose.forEach((el) => {
    if (el === getFormattedDateTodayUTC(timestamp)) {
      marketCloseUTCHour = 17;
    }
  });
  return marketCloseUTCHour;
};

//get all dates minus weekend and above dates
const listMarketOpenDates = (startDateParam, endDateParam) => {
  const dateList = [];
  let startDate;
  if (startDateParam) {
    startDate = startDateParam;
  } else {
    startDate = '2022-01-01';
  }
  let endDate;
  if (endDateParam) {
    endDate = endDateParam;
  } else {
    endDate = getFormattedDateTodayUTC();
  }

  const dateMove = new Date(startDate);
  let strDate = startDate;

  while (strDate < endDate) {
    strDate = dateMove.toISOString().slice(0, 10);
    const tempDate = new Date(strDate);
    if (tempDate.getUTCDay() !== 0 && tempDate.getUTCDay() !== 6) {
      dateList.push(strDate);
    }
    dateMove.setDate(dateMove.getDate() + 1);
  }
  const dateListNoHolidays = dateList.filter((el) => !holidays.includes(el));
  return dateListNoHolidays;
};

const getFormattedLastMarketCloseDate = () => {
  let endDate;
  if (marketStatus() === 'postmarket') {
    endDate = getFormattedDateTodayUTC();
  } else {
    endDate = getFormattedYesterdayDateUTC();
  }
  const date = listMarketOpenDates('2022-01-01', endDate).reverse()[0];
  return date;
};

const getFormattedSecondToLastMarketCloseDate = () => {
  let endDate;
  if (marketStatus() === 'postmarket') {
    endDate = getFormattedDateTodayUTC();
  } else {
    endDate = getFormattedYesterdayDateUTC();
  }
  const date = listMarketOpenDates('2022-01-01', endDate).reverse()[1];
  return date;
};

const getFormattedLastPreMarketTimestampIso = () => {
  let timestamp = new Date();
  if (marketStatus() === 'closed') {
    const lastCloseDate = new Date(getFormattedLastMarketCloseDate());
    timestamp = Date.UTC(
      lastCloseDate.getUTCFullYear(),
      lastCloseDate.getUTCMonth(),
      lastCloseDate.getUTCDate(),
      13,
      30,
      0,
      0
    );
  } else if (marketStatus() === 'open' || marketStatus() === 'postmarket') {
    timestamp = Date.UTC(
      timestamp.getUTCFullYear(),
      timestamp.getUTCMonth(),
      timestamp.getUTCDate(),
      13,
      30,
      0,
      0
    );
  } else if (marketStatus() === 'premarket') {
    //do nothing, return current timestamp
  }
  timestamp = new Date(timestamp);
  return timestamp.toISOString().substring(0, 19);
};

const getFormattedLastPostMarketTimestampIso = () => {
  let timestamp = new Date();
  if (
    marketStatus() === 'closed' ||
    marketStatus() === 'premarket' ||
    marketStatus() === 'open'
  ) {
    let lastCloseDate = new Date(getFormattedLastMarketCloseDate());
    lastCloseDate.setDate(lastCloseDate.getDate() + 1);
    timestamp = Date.UTC(
      lastCloseDate.getUTCFullYear(),
      lastCloseDate.getUTCMonth(),
      lastCloseDate.getUTCDate(),
      0,
      0,
      0,
      0
    );
  } else if (marketStatus() === 'premarket') {
    //do nothing, return current timestamp
  }
  //early close timestamp
  for (let i = 0; i < earlyClose.length; i++) {
    if (earlyClose[i] === getFormattedDateTodayUTC()) {
      timestamp = Date.UTC(
        timestamp.getUTCFullYear(),
        timestamp.getUTCMonth(),
        timestamp.getUTCDate(),
        21,
        0,
        0,
        0
      );
      break;
    }
  }
  timestamp = new Date(timestamp);
  return timestamp.toISOString().substring(0, 19);
};

//added offset
const getMinuteOfSession = (timestamp) => {
  const date = new Date(timestamp);
  const status = marketStatus(timestamp);
  let minBeforeSession = 13.5 * 60;
  if (status === 'premarket') minBeforeSession = 8 * 60;
  if (status === 'postmarket')
    minBeforeSession = getMarketCloseHour(timestamp) * 60;
  return date.getUTCHours() * 60 + date.getUTCMinutes() + getDstOffsetMins() - minBeforeSession;
};

//added offset
const getLastOpenTimestamp = (dateString, minuteMultiplier = 1) => {
  let date = new Date(dateString);
  let minusMinutes = minuteMultiplier % 60;
  let minusHours = (minuteMultiplier - minusMinutes) / 60;
  date.setUTCHours(
    getMarketCloseHour(new Date(dateString).getTime()) - 1 + (getDstOffsetMins()/60) - minusHours
  );
  date.setUTCMinutes(60 - minusMinutes);
  return date.getTime();
};

const getLastEowDateString = (dateString = getFormattedDateTodayUTC()) => {
  const marketDates = listMarketOpenDates(null, dateString).reverse();
  const lastFriDelta =
    new Date(dateString).getUTCDate() +
    (6 - new Date(dateString).getUTCDay() - 1) -
    7;
  const lastFri = new Date(dateString).setDate(lastFriDelta);
  const lastThuDelta =
    new Date(dateString).getUTCDate() +
    (6 - new Date(dateString).getUTCDay() - 2) -
    7;
  const lastThu = new Date(dateString).setDate(lastThuDelta);
  const lastWedDelta =
    new Date(dateString).getUTCDate() +
    (6 - new Date(dateString).getUTCDay() - 3) -
    7;
  const lastWed = new Date(dateString).setDate(lastWedDelta);
  const lastTueDelta =
    new Date(dateString).getUTCDate() +
    (6 - new Date(dateString).getUTCDay() - 4) -
    7;
  const lastTue = new Date(dateString).setDate(lastTueDelta);
  const lastMonDelta =
    new Date(dateString).getUTCDate() +
    (6 - new Date(dateString).getUTCDay() - 5) -
    7;
  const lastMon = new Date(dateString).setDate(lastMonDelta);
  for (let i = 1; i < marketDates.length; i++) {
    const date = marketDates[i];
    const timestamp = new Date(date).getTime();
    if (timestamp === lastFri) {
      return date;
    }
    if (timestamp === lastThu) {
      return date;
    }
    if (timestamp === lastWed) {
      return date;
    }
    if (timestamp === lastTue) {
      return date;
    }
    if (timestamp === lastMon) {
      return date;
    }
  }
};

const getLastEomDateString = (dateString = getFormattedDateTodayUTC()) => {
  const marketDates = listMarketOpenDates(null, dateString).reverse();
  for (let i = 1; i < marketDates.length; i++) {
    const date = marketDates[i];
    if (new Date(date).getUTCMonth() !== new Date(dateString).getUTCMonth()) {
      return date;
    }
  }
};

const getLastEoqDateString = (dateString = getFormattedDateTodayUTC()) => {
  const marketDates = listMarketOpenDates(null, dateString).reverse();
  for (let i = 1; i < marketDates.length; i++) {
    const date = marketDates[i];
    if (
      new Date(date).getUTCMonth() !== new Date(dateString).getUTCMonth() &&
      (new Date(date).getUTCMonth() + 1) % 3 === 0
    ) {
      return date;
    }
  }
};

const getLastEoyDateString = (dateString = getFormattedDateTodayUTC()) => {
  const marketDates = listMarketOpenDates('2021-12-01', dateString).reverse();
  for (let i = 1; i < marketDates.length; i++) {
    const date = marketDates[i];
    if (
      new Date(date).getUTCMonth() !== new Date(dateString).getUTCMonth() &&
      new Date(date).getUTCMonth() === 11
    ) {
      return date;
    }
  }
};

const getCalendarTimeframeAnchorDate = (calendarTimeframe) => {
  if (calendarTimeframe === 'W') {
    return getLastEowDateString();
  } else if (calendarTimeframe === 'M') {
    return getLastEomDateString();
  } else if (calendarTimeframe === 'Q') {
    return getLastEoqDateString();
  } else if (calendarTimeframe === 'Y') {
    return getLastEoyDateString();
  } else {
    //default to D
    return getFormattedLastMarketCloseDate();
  }
};

export {
  holidays,
  earlyClose,
  getMarketCloseHour,
  listMarketOpenDates,
  getFormattedLastMarketCloseDate,
  getFormattedSecondToLastMarketCloseDate,
  getFormattedLastPreMarketTimestampIso,
  getFormattedLastPostMarketTimestampIso,
  getMinuteOfSession,
  getLastOpenTimestamp,
  getLastEowDateString,
  getLastEomDateString,
  getLastEoqDateString,
  getLastEoyDateString,
  getCalendarTimeframeAnchorDate,
};
