import marketStatus from '../../../utils/marketStatus/marketStatus';
import {
  SET_REAL_TIME_DATA,
} from './actions';

const reducer = (state, action) => {
  if (action.type === SET_REAL_TIME_DATA) {
    const session = marketStatus(action.payload.latestTimestamp);
    let currentHlocv, lastHlocv;
    if (session === 'open') {
      currentHlocv = action.payload.openMarketHlocv;
      lastHlocv = action.payload.lastEodHlocv;
    } else if (session === 'premarket') {
      currentHlocv = action.payload.premarketHlocv;
      lastHlocv = action.payload.lastEodHlocv;
    } else if (session === 'postmarket') {
      currentHlocv = action.payload.postmarketHlocv;
      lastHlocv = action.payload.lastEodHlocv;
    } else if (session === 'closed') {
      currentHlocv = action.payload.lastEodHlocv;
      lastHlocv = action.payload.secondLastEodHlocv;
    }
    return {
      ...state,
      latestTimestamp: action.payload.latestTimestamp,
      session: session,
      ...(action.payload.tickerDetails && { dbTickers: Object.keys(action.payload.tickerDetails) }),
      ...(action.payload.tickerDetails && { tickerDetails: action.payload.tickerDetails }),
      ...(action.payload.tickerStats && { tickerStats: action.payload.tickerStats }),
      ...(action.payload.lastEodHlocv && { lastEodHlocv: action.payload.lastEodHlocv }),
      ...(action.payload.secondLastEodHlocv && { secondLastEodHlocv: action.payload.secondLastEodHlocv }),
      ...(action.payload.premarketHlocv && { premarketHlocv: action.payload.premarketHlocv }),
      ...(action.payload.openMarketHlocv && { openMarketHlocv: action.payload.openMarketHlocv }),
      ...(action.payload.postMarketHlocv && { postMarketHlocv: action.payload.postMarketHlocv }),
      ...(action.payload.trailingIntervals && { trailingIntervals: action.payload.trailingIntervals }),
      ...(action.payload.topBottomHour && { topBottomHour: action.payload.topBottomHour }),
      ...(currentHlocv && { currentHlocv }),
      ...(lastHlocv && { lastHlocv }),
      ...((action.payload.lastEodHlocv || action.payload.secondLastEodHlocv) && {
        eodUpdateReceived: state.eodUpdateReceived + 1,
      }),
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
