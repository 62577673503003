import {
  SET_IS_LOADING,
  SETUP_USER_SUCCESS,
} from './actions';

const reducer = (state, action) => {
  if (action.type === SET_IS_LOADING) {
    return { ...state, isLoading: action.payload };
  }
  if (action.type === SETUP_USER_SUCCESS) {
    return {
      ...state,
      id: action.payload._id,
      firstName: action.payload.firstName,
      lastName: action.payload.lastName,
      email: action.payload.email,
      isLoading: false,
    };
  }
  throw new Error(`no such action : ${action.type}`);
};

export default reducer;
