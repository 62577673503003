import React, { useContext } from 'react';
import { usePublicContext } from '../../../context/publicContext';
import createApi from '../../../utils/createApi';
import { useAuth0 } from '@auth0/auth0-react';
import { getFormattedDateTodayUTC, timeToLocal } from '../../../utils/marketStatus/dateTimeFunctions';
import { getFormattedLastMarketCloseDate } from '../../../utils/marketStatus/marketDates';
import marketStatus from '../../../utils/marketStatus/marketStatus';
import getTimeStr from '../../../utils/getTimeStr';

const ApiContext = React.createContext();

const ApiProvider = ({ children }) => {
  const { logout } = useAuth0();
  const { token } = usePublicContext();
  const api = createApi(token, logout);

  const getInitializationData = async () => {
    // contains tickerDetails and tickerStats
    const { data } = await api.get('/initialization');
    console.log(getTimeStr(), 'fetched', Object.keys(data));
    return data;
  };

  const getTimeSeries = async (ticker, timeframe) => {
    console.log('timeSeriesApi', ticker);
    const minBeforeOpenSession = 13.5 * 60;
    const minInOpenSession = 6.5 * 60;
    const candleMin = timeframe.multiplier;
    const fiveMinCandles = candleMin / 5;
    const reqObj = {
      ticker: ticker,
      //>30m polygon incorrectly blends pre / open market candles
      multiplier: candleMin <= 30 ? candleMin : 5,
      timespan: timeframe.timespan,
      startDate: timeframe.startDate, //going to need to fix this for > 30 minute timeframes
      endDate: getFormattedDateTodayUTC(),
    };
    const { data } = await api.post('stock/timeSeries', reqObj);
    let result = [];
    let tempResult = [];
    if (candleMin > 30) {
      // console.log('candle greater than 30m')
      //loop through all 5min candles
      for (let i = 0; i < data.length - 1; i++) {
        //find first candle of a given day
        const date = new Date(data[i].t);
        const hour = date.getUTCHours();
        const minute = date.getUTCMinutes();
        if (hour === 13 && minute === 30) {
          //opening minute of the day
          //loop forward through each new candle size of this day to compress hlocv
          //get the start minute of each candle
          for (let j = i; j < i + minInOpenSession; j++) {
            if (data[j]) {
              const status = marketStatus(data[j].t);
              const currentDate = new Date(data[j].t);
              if (
                status === 'open' &&
                date.toDateString() === currentDate.toDateString()
              ) {
                const minOfDay =
                  currentDate.getUTCHours() * 60 + currentDate.getUTCMinutes();
                const minOfSession = minOfDay - minBeforeOpenSession;
                if (minOfSession % candleMin === 0) {
                  // console.log(currentDate.toDateString(), currentDate.toLocaleTimeString())
                  //compress the next [candleMin] candles into one and push to tempResult
                  const t = data[j].t;
                  const time = timeToLocal(t);
                  let high = data[j].h;
                  let low = data[j].l;
                  const open = data[j].o;
                  let close;
                  let volume = 0;
                  for (let k = j; k < j + fiveMinCandles; k++) {
                    if (data[k]) {
                      const tempDate = new Date(data[k].t);
                      // console.log(tempDate.toDateString(), tempDate.toLocaleTimeString())
                      if (data[k]) {
                        if (data[k].h > high) {
                          high = data[k].h;
                        }
                        if (data[k].l < low) {
                          low = data[k].l;
                        }
                        close = data[k].c;
                        volume += data[k].v;
                      }
                    }
                  }
                  tempResult.push({
                    t: t,
                    time: time,
                    high: high,
                    low: low,
                    open: open,
                    close: close,
                    v: volume,
                  });
                }
              }
            }
          }
        }
      }
    }
    data.forEach((el) => {
      if (reqObj.timespan === 'week') {
        let mondayDate = new Date(el.t);
        mondayDate.setDate(mondayDate.getDate() + 1);
        result.push({
          t: el.t,
          time: getFormattedDateTodayUTC(mondayDate),
          open: el.o,
          high: el.h,
          low: el.l,
          close: el.c,
          v: el.v,
        });
      } else if (reqObj.timespan === 'day' || reqObj.timespan === 'month') {
        result.push({
          t: el.t,
          time: getFormattedDateTodayUTC(el.t),
          open: el.o,
          high: el.h,
          low: el.l,
          close: el.c,
          v: el.v,
        });
      } else {
        result.push({
          t: el.t,
          time: timeToLocal(el.t),
          open: el.o,
          high: el.h,
          low: el.l,
          close: el.c,
          v: el.v,
        });
      }
    });
    if (tempResult.length > 0) {
      // console.log('tempResult', tempResult);
      result = tempResult;
    }
    return result;
  };

  const getOptionsChain = async (ticker) => {
    // console.log('test api call attempt with token', token);
    const body = {
      ticker: ticker,
    };
    const { data } = await api.post('/options/getOptionsChain', body);
    console.log('api response:', data);
    return data;
  };

  const getTickerBigTrades = async (ticker, startDate = null, endDate = null) => {
    const body = {
      ticker: ticker,
      startDate: startDate,
      endDate: endDate,
    };
    const { data } = await api.post('/stock/tickerBigTrades', body);
    console.log('api response:', data);
    return data;
  }

  return (
    <ApiContext.Provider
      value={{
        getInitializationData,
        getTimeSeries,
        getOptionsChain,
        getTickerBigTrades,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

const useApiContext = () => {
  return useContext(ApiContext);
};

export { ApiProvider, useApiContext };
